import React from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Carousel,
    IconButton
  } from "@material-tailwind/react";  
import LogoFooter from '../assets/logoFooter.png';
import Di from '../assets/di.png';
import wa from '../assets/whatsapp.svg'
import menu from '../assets/menu.svg'
import contact from '../assets/contact.svg'
import badge from '../assets/badge.svg'
import service from '../assets/service.svg'
import projects from '../assets/projects.svg'
import headerIcon from '../assets/headerIcon.svg'
import bills from '../assets/bill.png'
import ceo from '../assets/ceo.png'
import notepad from '../assets/notepad.png'
import paperbag from '../assets/paperbag.png'
import pcap from '../assets/pcap.png'
import pcaps from '../assets/pcaps.png'
import './input.css'
import { CarouselDefault } from "./carousel";
import { Profile } from "./profile";
const handleContact = () => {
    const element = document.getElementById('contact-us');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
}
const handleOurServices = () => {
    const element = document.getElementById('our-service');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
}
const handleAboutUs = () => {
    const element = document.getElementById('about-us');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
}
const handleProjects = () => {
    const element = document.getElementById('projects');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
}
const Home2 = () => {
const [open, setOpen] = React.useState(0);

const handleOpen = (value) => setOpen(open === value ? 0 : value);
  
return (
<div class="">
    {/* <div class="pl-[63px] pr-[69.47px] pt-[26px] pb-[25px] left-0 top-[97px] absolute bg-white justify-start items-center inline-flex">
        <div class="self-stretch justify-start items-center gap-[1207px] inline-flex">
            <div class="w-[46px] h-[46px] rounded-[5px] justify-center items-center flex">
                <img class="w-[59.67px] h-[59.40px]" src="https://via.placeholder.com/60x59" />
            </div>
        </div>
    </div> */}
    <nav className="relative container mx-auto p-6">
        <div className="flex items-center justify-between">
            <div className="pt-2">
                <img src={headerIcon} alt="log"/>
            </div>
            <div className="hidden md:hidden md:flex space-x-6">
                <a href="#" className="hover:text-red-500">Home</a>
                <a href="#">Services</a>
                <a href="/about">About Us</a>
                <a href="#">Contact Us</a>
            </div>
            <a href="#" className="hidden md:block p-3 px-6 pt-2 text-white bg-red-500 rounded-full baseline hover:bg-red-300">
                Get Started
            </a> 
            <button id="menu-btn" className="block hamburger md:hidden focus:outline-none">
                <span className="hamburger-top"></span>
                <span className="hamburger-middle"></span>
                <span className="hamburger-bottom"></span>
            </button>
        </div>
        <div className="md:hidden">
            <div id="menu" className="absolute bg-[#D00228] flex flex-col items-center self-end hidden py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md">
                <a href=''>home</a>
                <a href=''>home</a>
                <a href=''>home</a>

            </div>
        </div>
    </nav>
    
    <section>
        <div className="container flex flex-col item-center px-6 mx-auto mt-10 md:flex-row">
            <div className="flex flex-col text-[#D00228]  mb-2 font-bold font-['Plus Jakarta Sans'] space-y-1 md:w-1/2">
                <h1 className="max-w-md text-7xl font-bold text-start">
                    CREATIVE
            </h1>
            </div>
            <div className="md:w-1/2">
            <h1 className="hidden max-w-md text-3xl text-[#D00228] font-bold font-normal text-center font-['Plus Jakarta Sans'] md:text-start md:block">
            Where your branding idea comes to life
            </h1>
            </div>           
        </div>
        <div className="container flex flex-col item-center px-6 mx-auto mt-1 md:flex-row md:mt-1">
            <div className="hidden flex  flex-col text-[#D00228]  mb-32 font-bold font-['Plus Jakarta Sans'] space-y-0 md:w-1/4 md:block">
                    <h1 className="max-w-md text-4xl font-bold text-start text-[#D00228] font-normal font-['Plus Jakarta Sans']">
                    Established<br/>2018
                </h1>
                </div>
                <div className="md:w-75">
                <h1 className="text-7xl text-center text-[#D00228] font-bold font-['Plus Jakarta Sans'] md:text-end">
                  MEDIA AGENCY
                </h1>
                </div>
        </div>
    </section>
   
    <section id="hero">
        <div className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
        
            
            <div className="hidden h-full flex flex-col justify-between space-y-10 md:w-1/4 md:block">
            <a href="#about-us" class="group block max-w-xs mx-auto">
                <div class="flex group block justify-center text-white bg-[#D00228] h-[50px] baseline hover:bg-rose-300 items-center text-center font-normal font-['Plus Jakarta Sans']">
                    About Us
                </div>
            </a>
            <a href="#services" class="group block max-w-xs mx-auto">
                <div class="flex group block justify-center text-white bg-[#D00228] h-[50px] baseline hover:bg-rose-300 items-center text-center font-normal font-['Plus Jakarta Sans']">
                    Our Services
                </div></a>
            <a href="#projects" class="group block max-w-xs mx-auto">
                <div class="flex group block justify-center text-white bg-[#D00228] h-[50px] baseline hover:bg-rose-300 items-center text-center font-normal font-['Plus Jakarta Sans']">
            
                    Projects
                </div>
                </a>
            <a href="#contact" class="group block max-w-xs mx-auto">
                <div class="flex group block justify-center text-white bg-[#D00228] h-[50px] baseline hover:bg-rose-300 items-center text-center font-normal font-['Plus Jakarta Sans']">
            
                    Contact Us
                </div>
            </a>
                
            </div>
            <div className="md:w-75 md:h-1/2 md: pl-10">
                <img src={Di} alt="" />
            </div>
        </div>
    </section>
    <section id="about-us">
        <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">
            <div className="flex flex-col space-y-12 md:w-1/2">
                <h2 className="max-w-md text-7xl font-bold text-start text-[#D00228]">About Us</h2>
            </div>
            <div className="flex flex-col space-y-12 md:w-1/2">
                <h2 className="text-3xl text-start text-[#D00228]">We are a coporate branding and printing company</h2>
            </div>
        </div>
        <div className="container flex flex-col px-4 mx-auto mb-10 mt-10 space-y-12 md:space-y-0 md:flex-row">
                <div className="md:w-full md:h-1/2 bg-red-300">
                <img src={bills} className="h-1/2 w-full" alt="" />
            </div>
            <div className="flex space-y-8 md:justify-end md:w-1/2">
            <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6">
                {/* <div className="rounded-l-full bg-red-400 md:bg-red-0">
                    <div className="flex items-center space-x-2">
                        <div className="px-4 py-2 text-white rounded-full md:py-1 bg-red-400">
                            01
                        </div>
                        <h3 className="text-base font-bold md:mb-4 md:hidden">
                            track  company
                        </h3>
                    </div>
                </div> */}
                <div className="h-full flex flex-col justify-between space-y-10 md:ml-3 md:w-1/4 md:block">
               
                <div class="flex justify-center w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">

                   <Accordion open={open === 1}>
                    <AccordionHeader onClick={() => handleOpen(1)}>
                    <div class="flex justify-center text-white bg-[#D00228] h-[50px] w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">

                    6 years of Experience Us
                    </div>

                    </AccordionHeader>
                    <AccordionBody>
                    Over the years, we’ve designed and built for personal and corporate brands. We’ve positioned ourselves as leaders in digital media and branding.
                    </AccordionBody>
                </Accordion>
                </div>
              
                <div class="flex justify-center w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">
                   <Accordion open={open === 2}>
                    <AccordionHeader onClick={() => handleOpen(2)}>
                    <div class="flex justify-center text-white bg-[#D00228] h-[50px] w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">

                    120+ Completed Projects
                    </div>

                    </AccordionHeader>
                    <AccordionBody>
                        ''
                    </AccordionBody>
                </Accordion>
               </div>
               <div class="flex justify-center w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">
                   <Accordion open={open === 3}>
                    <AccordionHeader onClick={() => handleOpen(3)}>
                    <div class="flex justify-center text-white bg-[#D00228] h-[50px] w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">

                    100+ Happy Clients
                    </div>

                    </AccordionHeader>
                    <AccordionBody>
                        ''
                    </AccordionBody>
                </Accordion>
               </div>
               <div class="flex justify-center w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">
                   
                   <Accordion open={open === 4}>
                    <AccordionHeader onClick={() => handleOpen(4)}>
                    <div class="flex justify-center text-white bg-[#D00228] h-[50px] w-[250px] items-center text-center font-normal font-['Plus Jakarta Sans']">

                    Management Team
                    </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <a href='/files/profile.pdf'>
                            Download profile
                        </a>
                    </AccordionBody>
                </Accordion>
               </div>
           </div>
                
            </div>
            </div>
        </div>
    </section>
    <section id="services" >
        <div className="container flex flex-col px-4 mx-auto md:mt-[100px] space-y-12 md:space-y-5 md:flex-row">
            <div className="flex flex-col space-y-12 md:w-1/2">
                {/* <hr className=" text-7xl w-[100px] mb-0 font-bold col-md-1"/> */}
                <h2 className="max-w-md text-7xl font-bold font-['Plus Jakarta Sans'] text-start text-[#D00228]">Services</h2>
            </div>
            <div className="flex flex-col space-y-12 md:w-1/2">
                <h2 className="text-3xl text-start text-[#D00228]">We pride ourselves in being exceptional</h2>
            </div>
        </div>
        <div className="container flex flex-col px-4 mx-auto md:mt-[100px] space-y-12 md:space-y-5 md:flex-row">
                
        <CarouselDefault />

        </div>
        <div className="container  w-full flex flex-col px-4 mx-auto mb-10 mt-10 space-y-12 md:space-y-0">
            <Accordion open={open === 5}>
            <AccordionHeader onClick={() => handleOpen(5)}>
                <div class="flex py-3 w-full px-5 text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans'] bg-rose-300 h-[75px] hover:bg-rose-200 ">
                    Branding
                </div>
            </AccordionHeader>
            <AccordionBody>
            
            </AccordionBody>
        </Accordion>
        </div>
        <div className="container  w-full flex flex-col px-4 mx-auto mb-10 mt-10 space-y-12 md:space-y-0">
            <Accordion open={open === 6}>
            <AccordionHeader onClick={() => handleOpen(6)}>
                <div class="flex py-3 w-full px-5 text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans'] bg-rose-300 h-[75px] hover:bg-rose-200 ">
                Printing
                </div>
            </AccordionHeader>
            <AccordionBody>
            
            </AccordionBody>
        </Accordion>
        </div>
        <div className="container  w-full flex flex-col px-4 mx-auto mb-10 mt-10 space-y-12 md:space-y-0">
            <Accordion open={open === 7}>
            <AccordionHeader onClick={() => handleOpen(7)}>
                <div class="flex py-3 w-full px-5 text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans'] bg-rose-300 h-[75px] hover:bg-rose-200 ">
                Public Relations & Digital Marketing
                </div>
            </AccordionHeader>
            <AccordionBody>
            
            </AccordionBody>
        </Accordion>
        </div>
    </section>
    <section id="projects" >
        <div className="container flex flex-col px-4 mx-auto md:mt-[100px] space-y-12 md:items-center md:space-y-5 md:flex-row">
            <div className="flex flex-row space-y-12 md:w-1/2">
                {/* <hr className=" text-7xl w-[100px] mb-0 font-bold col-md-1"/> */}
                <h2 className="max-w-md text-7xl font-bold font-['Plus Jakarta Sans'] text-start text-[#D00228]">Projects</h2>
                <hr className="w-[100px] h-[20px] mx-auto h-2 my-8 bg-[#D00228]" />
            </div>
            <div className="flex flex-col space-y-12 md:w-1/2">
                <h2 className="text-3xl text-start text-[#D00228]">We are trusted by all - NGOs,
                    Government agencies, International
                & Local Companies</h2>
            </div>
        </div>
        <div className="container flex flex-col px-4 mx-auto mb-10 mt-10 space-y-12 md:space-y-0 md:flex-row">
                <div className="md:w-full md:h-1/2">
                <img src={Di} alt="" />
            </div>
        </div>
    </section>
    <footer id='contact' className="bg-[#D00228] py-5">
        <div className="container flex flex-col justify-between px-6 py-10-py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
            <div className="flex flex-col item-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start">
            <div class="w-full h-[53.63px] mb-5 text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']">Let’s be your Brand Partner!</div>
                <div class="w-full h-[47.94px] justify-center relative">
                    <div class="w-[208.84px] h-[47.94px] left-0 top-0 absolute bg-white rounded"></div>
                    <div class="left-[25.19px] top-[7.31px] absolute justify-start items-center gap-[21.13px] inline-flex">
                    <a href="https://web.whatsapp.com/send/?phone=2348140659666" target='_blank'>
                        <div class="text-[#D00228] text-xl font-medium font-['Plus Jakarta Sans']">Get in touch</div>
                    </a>
                            <div style={{width: 32.50, height: 32.50, position: 'relative'}}>
                                <img src={wa} style={{width: 31.96, height: 32.11, left: 0.26, top: 0.18, position: 'absolute'}} /> 
                            </div>
                        </div>
                </div>
                <div class="items-center flex-col md:flex-row md:items-start gap-px inline-flex">
                    <div class="text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']">08110501025, </div>
                    <div class="w-[286px] text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']"> 08140659666</div>
                </div>
            </div>
            <div className="flex justify-around space-x-32">
                {/* <div className="flex flex-col space-y-3 text-white">
                <a href="#">
                        <p>x</p>
                    </a>
                    <a href="#">
                        <p>fb</p>
                    </a>
                </div> */}
                <div className="flex flex-col space-y-3 text-white">
                    <img src={LogoFooter} className="h-40 w-40 rounded" alt="" />
                </div>
            </div>
        </div>
    </footer>

    {/* <div class="w-[1193px] h-[261px] left-[116px] top-[199px] absolute">
        <div class="w-[1193px] h-[261px] left-0 top-0 absolute">
            <div class="w-[584.44px] left-0 top-0 absolute text-[#D00228] text-[113.60px] font-bold font-['Plus Jakarta Sans']">CREATIVE  </div>
            <div class="w-[901px] left-[292px] top-[118px] absolute text-[#D00228] text-[113.60px] font-bold font-['Plus Jakarta Sans']">MEDIA  AGENCY</div>
        </div>
        <div class="w-[609px] left-[584px] top-[22px] absolute text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">Where your branding idea comes to life</div>
        <div class="left-[19px] top-[150px] absolute text-[#D00228] text-[34.16px] font-normal font-['Plus Jakarta Sans']">Established<br/>2018</div>
    </div>
    <div class="left-[135px] top-[542px] absolute justify-start items-start gap-[103px] inline-flex">
        <div class="h-[525px] flex-col justify-start items-start gap-[29px] inline-flex">
            <div class="w-[216px] h-[109px] relative rounded-[5px]">
                <div class="w-[216px] h-[109px] left-0 top-0 absolute bg-[#D00228] rounded-[5px] shadow"></div>
                <div class="left-[31px] top-[37px] absolute justify-start items-center gap-2.5 inline-flex">
                    <div class="w-6 h-6 relative">
                        <div class="w-6 h-6 left-0 top-0 absolute bg-zinc-300"></div>
                    </div>
                    <div class="text-white text-[28px] font-normal font-['Plus Jakarta Sans']">About Us</div>
                </div>
            </div>
            <div class="w-[216px] h-[109px] relative">
                <div class="w-[216px] h-[109px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px] shadow"></div>
                <div class="left-[27px] top-[36px] absolute text-[#D00228] text-[28px] font-normal font-['Plus Jakarta Sans']">Our Services</div>
            </div>
            <div class="w-[216px] h-[109px] relative">
                <div class="w-[216px] h-[109px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px] shadow"></div>
                <div class="left-[52px] top-[37px] absolute text-[#D00228] text-[28px] font-normal font-['Plus Jakarta Sans']">Projects</div>
            </div>
            <div class="w-[216px] h-[109px] relative">
                <div class="w-[216px] h-[109px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px] shadow"></div>
                <div class="left-[33px] top-[37px] absolute text-[#D00228] text-[28px] font-normal font-['Plus Jakarta Sans']">Contact Us</div>
            </div>
        </div>
        <div class="w-[809px] h-[525px] relative">
            <div class="w-[805.05px] h-[525px] left-[3.95px] top-0 absolute"></div>
            <div class="w-[809px] h-[525px] left-0 top-0 absolute">
                <div class="w-[805.05px] h-[525px] left-[3.95px] top-0 absolute bg-zinc-300"></div>
                <img class="w-[809px] h-[525px] left-0 top-0 absolute" src="https://via.placeholder.com/809x525" />
            </div>
        </div>
    </div>
    <div class="w-[1122.73px] left-[135px] top-[1182px] absolute justify-between items-center inline-flex">
        <div class="w-[404.73px] text-[#D00228] text-[85px] font-bold font-['Plus Jakarta Sans']">About Us</div>
        <div class="w-[103px] h-[0px] relative"></div>
        <div class="w-[519px] text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">We are a corporate branding <br/>and printing company</div>
    </div>
    <div class="w-[1122.73px] left-[140px] top-[2052px] absolute justify-between items-center inline-flex">
        <div class="w-[404.73px] text-[#D00228] text-[85px] font-bold font-['Plus Jakarta Sans']">Services</div>
        <div class="w-[103px] h-[0px] relative"></div>
        <div class="w-[519px] text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">We pride ourselves in being exceptional</div>
    </div>
    <div class="w-[1159px] left-[127px] top-[3364px] absolute justify-between items-center inline-flex">
        <div class="w-[356px] text-[#D00228] text-[85px] font-bold font-['Plus Jakarta Sans']">Projects</div>
        <div class="w-[103px] h-[0px] justify-center items-center flex">
            <div class="w-[103px] h-[0px] relative flex-col justify-start items-start flex"></div>
        </div>
        <div class="w-[566px] text-[#D00228] text-[33px] font-normal font-['Plus Jakarta Sans']">We are trusted by all - NGOs,<br/>Government agencies,  International<br/> & Local Companies</div>
    </div>
    <div class="w-[408px] h-[109px] left-[840px] top-[1539px] absolute">
        <div class="w-[408px] h-[109px] left-0 top-0 absolute bg-[#D00228] rounded-[5px] shadow"></div>
        <div class="left-[18px] top-[37px] absolute justify-end items-center gap-[7px] inline-flex">
            <div class="opacity-80 justify-start items-center gap-2.5 flex">
                <div class="text-white text-[28px] font-normal font-['Plus Jakarta Sans']">120+ Completed Projects</div>
            </div>
            <div class="w-6 h-6 relative">
                <div class="w-6 h-6 left-0 top-0 absolute bg-zinc-300"></div>
            </div>
        </div>
    </div>
    <div class="w-[408px] h-[109px] left-[840px] top-[1692px] absolute">
        <div class="w-[408px] h-[109px] left-0 top-0 absolute bg-[#D00228] rounded-[5px] shadow"></div>
        <div class="left-[54px] top-[37px] absolute justify-end items-center gap-2.5 inline-flex">
            <div class="text-white text-[28px] font-normal font-['Plus Jakarta Sans']">100+ Happy  Clients</div>
            <div class="w-6 h-6 relative">
                <div class="w-6 h-6 left-0 top-0 absolute bg-zinc-300"></div>
            </div>
        </div>
    </div>
    <div class="w-[408px] h-[109px] left-[840px] top-[1845px] absolute">
        <div class="w-[408px] h-[109px] left-0 top-0 absolute bg-[#D00228] rounded-[5px] shadow"></div>
        <div class="left-[54px] top-[37px] absolute justify-end items-center gap-2.5 inline-flex">
            <div class="text-white text-[28px] font-normal font-['Plus Jakarta Sans']">Management Team</div>
            <div class="w-6 h-6 relative">
                <div class="w-6 h-6 left-0 top-0 absolute bg-zinc-300"></div>
            </div>
        </div>
    </div>
    <div class="w-[408px] h-[109px] left-[840px] top-[1386px] absolute">
        <div class="w-[408px] h-[109px] left-0 top-0 absolute bg-[#D00228] rounded-tl-[5px] rounded-tr-[5px] shadow"></div>
        <div class="left-[43px] top-[37px] absolute justify-end items-end gap-[15px] inline-flex">
            <div class="opacity-80 justify-start items-center gap-2.5 flex">
                <div class="text-white text-[28px] font-normal font-['Plus Jakarta Sans']">8 Years of Experience</div>
            </div>
            <div class="w-6 h-6 relative">
                <div class="w-6 h-6 left-0 top-0 absolute bg-zinc-300"></div>
            </div>
        </div>
    </div>
    <div class="w-[1151px] h-[538px] left-[122px] top-[2227px] absolute">
        <div class="w-[1151px] h-[538px] left-0 top-0 absolute bg-[#D00228] rounded-[5px]"></div>
        <div class="left-[63px] top-[47px] absolute text-white text-[38.57px] font-normal font-['Plus Jakarta Sans']">Website Design</div>
    </div>
    <div class="w-[1149px] h-[110px] left-[122px] top-[2814px] absolute">
        <div class="w-[1149px] h-[110px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px]"></div>
        <div class="left-[94px] top-[31px] absolute text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">Branding</div>
    </div>
    <div class="w-[1149px] h-[110px] left-[122px] top-[2973px] absolute">
        <div class="w-[1149px] h-[110px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px]"></div>
        <div class="left-[94px] top-[31px] absolute text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">Printing</div>
    </div> */}
    {/* <div class="w-[1149px] h-[110px] left-[123px] top-[3127px] absolute">
        <div class="w-[1149px] h-[110px] left-0 top-0 absolute bg-[#D00228] bg-opacity-20 rounded-[5px]"></div>
        <div class="left-[94px] top-[31px] absolute text-[#D00228] text-[38.57px] font-normal font-['Plus Jakarta Sans']">Public Relations & Digital Marketing</div>
    </div> */}
    {/* <div class="w-100 left-0 top-[4516px] absolute font-['Plus Jakarta Sans'] bg-rose-800">
        <div class="h-[596px] left-0 top-0 absolute bg-red-100 rounded-[5px] font-['Plus Jakarta Sans w-[1000px]"></div>
        <div class="left-[99px] top-[172px] absolute justify-end items-center gap-[459px] inline-flex">
            <div class="flex-col justify-start items-start gap-[50.38px] inline-flex">
                <div class="w-[531.44px] h-[53.63px] text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']">Let’s be your Brand Partner!</div>
                <div class="w-[208.84px] h-[47.94px] relative">
                    <div class="w-[208.84px] h-[47.94px] left-0 top-0 absolute bg-white rounded"></div>
                    <div class="left-[25.19px] top-[7.31px] absolute justify-start items-center gap-[21.13px] inline-flex">
                        <div class="text-[#D00228] text-xl font-medium font-['Plus Jakarta Sans']">Get in touch</div>
                        <div class="w-[32.50px] h-[32.50px] relative"></div>
                    </div>
                </div>
                <div class="justify-start items-start gap-px inline-flex">
                    <div class="text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']">08110501025,</div>
                    <div class="w-[286px] text-white text-[39.38px] font-bold font-['Plus Jakarta Sans']">08140659666</div>
                </div>
            </div>
            <div class="h-[225px] rounded-3xl justify-center items-center flex">
                <img class="w-[225px] h-[225px]" src="https://via.placeholder.com/225x225" />
            </div>
        </div>
    </div> */}
</div>

)
}

export default Home2;