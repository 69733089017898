import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Document, Page } from 'react-pdf';
import profile from '../files/profile.pdf'
import con from '../assets/ceo.png'
import SEO from './seo';

const About = () => {
//   const [numPages, setNumPages] = useState('');
//   const [pageNumber, setPageNumber] = useState(1);


  return (
    <div>
    <SEO
title='About labarai'
description='itjkkkkkkkkk kjfffff'
image="https://cdn.ghanaweb.com/imagelib/pics/900/90004625.295.jpg"
name='contest'
type='article' />
        PDF
    </div>
  );
}
export default About;