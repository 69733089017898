import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import logo from './logo.svg';
import './App.css';
import Home from './component/home';
import Home2 from './component/home2';
import About from "./component/about";
const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
    <Router>
        <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/about" element={<About />} />
          {/* <Route exact path="/about">
            <About />
          </Route> */}
        </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
