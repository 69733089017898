import React from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import notepad from '../assets/notepad.png'
import paperbag from '../assets/paperbag.png'
import pcaps from '../assets/pcaps.png'


export function CarouselDefault() {
    return (
        <div className="container flex flex-col px-4 mx-auto md:mt-[100px] space-y-12 md:space-y-5 md:flex-row">

        <Carousel autoPlay centerSlidePercentage={70} centerMode infiniteLoop interval={2000} showThumbs={false}>
            <div>
                <img src={notepad} />
            </div>
            <div>
                <img src={paperbag} />
            </div>
            <div>
                <img src={pcaps} />
            </div>
        </Carousel>
        </div>
    );
}